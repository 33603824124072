import React from "react"
import Electricity from "../../components/body/pages/bill-payments/electricity"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ElectricityPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/bill-payments/electricity/"}
      title="Pay Electricity Bills Online | Prepaid Meter Recharge | Kuda"
      description="Buy electricity units for PHED, AEDC, EEDC, EKDC, KEDCO, IBEDC, BEDC, JEDC, KAEDCO & IKEDC. Join Kuda today, the money app for Africans."
    />
    <Electricity />
  </Layout>
)

export default ElectricityPage
